<script>

  import TryButton from "$lib/TryButton.svelte"

</script>
<section class="relative overflow-hidden">
  <!-- Text Content -->
  <div class="relative z-10 text-center max-w-3xl mx-auto px-4 py-24">
    <h2 class="text-4xl md:text-5xl lg:text-6xl font-bold mb-6">
      <span class="text-[#43444d]">One</span>
      <span class="text-[#536aff]">Link</span>.
      <span class="text-[#43444d]">Zero</span>
      <span class="text-[#8a8c94]">Noise</span>
    </h2>
    
    <p class="text-[#8a8c94] text-lg mb-12 max-w-2xl mx-auto">
      Meet only with serious individuals who value your time. Share your personal AddMeetings link across various platforms — such as email and LinkedIn — with anyone who wants to schedule a call with you. Receive only relevant meeting requests and reduce the noise completely.
    </p>
    
    <TryButton>It's Free - Try Now!</TryButton>
  </div>

  <!-- Video Section -->
  <div class="relative overflow-hidden aspect-video">
    <img
      src="/images/man-in-video-meeting.webp"
      alt="Man participating in video meeting on his laptop"
      class="w-full h-full object-cover"
    />
    
    <!-- Play Button -->
    <!-- <a href="https://youtu.be/7gSFB1X--88"
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 
              bg-white/20 hover:bg-white/30 transition-colors p-8 rounded-full 
              backdrop-blur-sm group"
    >
      <svg 
        class="w-12 h-12 text-white group-hover:scale-110 transition-transform" 
        viewBox="0 0 24 24" 
        fill="currentColor"
      >
        <path d="M8 5v14l11-7z"/>
      </svg>
      <span class="sr-only">Play video</span>
    </a> -->
  </div>
</section>