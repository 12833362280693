<script>
  import TryButton from "$lib/TryButton.svelte"
  import WatchDemoButton from "$lib/WatchDemoButton.svelte"

</script>

<div class="">
  <main class="container mx-auto px-4 pt-20 pb-32">
    <div class="max-w-4xl">
      <h1 class="text-5xl md:text-6xl lg:text-7xl font-bold leading-tight text-[#070811]">
        Less
        <span class="text-[#8a8c94]">Noise</span>.
        <span class="text-[#8a8c94]">More</span><br/>
        <span class="text-[#8a8c94]">Meaningful</span>
        Meetings.
      </h1>
      
      <p class="mt-6 text-[#43444D] text-xl max-w-2xl">
        Say goodbye to cold sales outreach. Get only high-value meeting requests sent straight to your inbox and make an impact with every meeting you take.
      </p>
      
      <div class="mt-12 flex flex-wrap gap-4">
        <TryButton>Try for Free</TryButton>
        <WatchDemoButton href="https://youtu.be/7gSFB1X--88" />
      </div>
    </div>
  </main>
</div>

<div class="relative">
  <img 
    src="/images/person-having-a-meeting.webp" 
    alt="A person having a virtual meeting"
    class="w-full"
  />
  <a href="https://youtu.be/7gSFB1X--88" target="_blank" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white/20 hover:bg-white/30 transition-colors p-8 rounded-full backdrop-blur-sm">
    <svg class="w-12 h-12 text-white" viewBox="0 0 24 24" fill="currentColor">
      <path d="M8 5v14l11-7z"/>
    </svg>
  </a>
</div>

<style>
  :global(body) {
    margin: 0;
    font-family: system-ui, -apple-system, sans-serif;
  }
</style>
